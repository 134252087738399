<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'EventList',
              }"
            >{{ '活動管理' }}
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'EventList',
              }"
            >{{ '活動列表' }}
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'EventView',
                params: { event_id: $route.params.event_id },
              }"
            >{{ '查看活動' }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active>{{ "兌換序號清單" }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
                兌換碼清單
              </h4>
              <b-input-group class="col-12 col-xl-4 mb-2">
                <b-form-input v-model="keyword" placeholder="搜尋兌換碼、使用人"></b-form-input>
                <b-input-group-append>
                  <b-button @click="fetchRedeemCodes"><i class="fa fa-search"></i ></b-button>
                </b-input-group-append>
              </b-input-group>
              <div class="col-12 col-xl-8 mt-3">
                <div class="row">
                  <div class="col-lg-2 col-12">活動名稱：</div>
                  <div class="col-lg-10 col-12">{{ eventData.name }}</div>
                </div>
                <div class="row mt-1">
                  <div class="col-lg-2 col-12">活動代碼：</div>
                  <div class="col-lg-10 col-12">{{ eventData.code }}</div>
                </div>
                <div class="row mt-1">
                  <div class="col-lg-2 col-12">活動期間：</div>
                  <div class="col-lg-10 col-12">{{ eventData.start_at }} ~ {{ eventData.end_at }}</div>
                </div>
              </div>

            </div>

            <b-card-text>
              <div class="row">
                <div class="col-12">
                  <b-overlay :show="showLoading" rounded="sm">
                    <b-table
                      striped
                      hover
                      responsive
                      :items="redeemCodes"
                      :fields="fields"
                    >
                    </b-table>
                  </b-overlay>
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="center"
                  @change="fetchRedeemCodes"
                ></b-pagination>
              </div>
            </b-card-text>
          </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import eventApi from "../../../apis/event";
import { format } from "date-fns";

export default {
  data() {
    return {
      showLoading: false,
      validationErrors: null,
      eventData: {
        name: null,
        code: null,
        start_at: null,
        end_at: null,
      },
      keyword: null,
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      redeemCodes: [],
      fields: [
        {
          key: "code",
          label: "兌換碼",
        },
        {
          key: "redeem_at",
          label: "兌換時間",
          formatter: (value) => {
            return value ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss') : null;
          },
        },
        {
          key: "customer",
          label: "使用人",
          formatter: (value) => {
            return value && value.name;
          },
        },
      ],
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  mounted() {
    this.fetchEvent();
    this.fetchRedeemCodes();
  },
  watch: {
    currentPage() {
      this.fetchRedeemCodes();
    }
  },
  methods: {
    async fetchEvent() {
      this.showLoading = true;
      const response = await eventApi.getEvent(this.organization, this.$route.params.event_id);
      this.eventData = response.data.data;
      this.eventData.start_at = format(new Date(this.eventData.start_at), 'yyyy-MM-dd')
      this.eventData.end_at = format(new Date(this.eventData.end_at), 'yyyy-MM-dd')
      this.showLoading = false;
    },
    async fetchRedeemCodes() {
      this.showLoading = true;
      let params = {
        per_page: this.perPage,
        page: this.currentPage,
        keyword: this.keyword,
      };
      const response = await eventApi.getRedeemCodes(this.organization, this.$route.params.event_id, params);
      this.redeemCodes = response.data.data;
      this.totalRows = response.data.meta.total;
      this.showLoading = false;
    },
    handleCancel() {
      this.$router.push({name: "EventList"});
    },
  },
};
</script>
